import { Button, Divider, Typography, Modal} from '@arco-design/web-react';
import styles from "./index.module.less"
import {useEffect, useState} from "react";
import {Avatar}  from "../../components";
import { observer } from "mobx-react-lite"
import {userSelf,follow} from "../../store";
import User from "../../store/user";

export default observer(function ({size='small',user={},followed}){
    const {name,avatar,introduce,_id,followers}=user
    const [isFollowed,setIsFollowed]=useState(followed)
    const [userStore]=useState(new User())
    const [isSelf,setIsSelf]=useState(userSelf.id&&userSelf.id===user._id)
    useEffect(()=>{
        setIsFollowed(followed)
    },[followed])
    useEffect(()=>{
        setIsSelf(userSelf.id&&userSelf.id===user._id)
        user._id&&userStore.getUserInfo(user._id)
    },[userSelf.id,user._id])
    const handleFollow=async ()=>{
        await follow.follow(_id)
        setIsFollowed(true)
    }
    const handleUnfollow=()=>{
        Modal.confirm({
            title: '是否确认取消关注？',
            onOk: async () => {
                await follow.unfollow(_id)
                setIsFollowed(false)
            },
        });
    }

    const getFollowButton=(size='default')=>{
        if(isSelf) return null
        return isFollowed?
            <Button size={size} className={styles.follow} onClick={handleUnfollow}>取消关注</Button>:
            <Button size={size} className={styles.follow} type={'primary'} onClick={handleFollow}>关注</Button>
    }
    return (
        <div className={styles.author}>
            {
                size==='small'&&(
                    <div className={styles.small}>
                        <div className={styles.left}>
                            <Avatar key={_id} className={styles.avatar} user={user} src={avatar}/>
                            <div className={styles.box}>
                                <div className={styles.name}>{name}</div>
                                <div className={styles.intro}>{introduce}</div>
                            </div>
                        </div>
                        <div className={styles.right}>
                            {
                                getFollowButton('mini')
                            }
                        </div>
                    </div>
                )
            }
            {
                size==='middle'&&(
                    <div className={styles.middle}>
                        <div className={styles.avatarWrap}>
                            <Avatar className={styles.avatar} src={avatar} user={user} key={_id} size={50}/>
                            <div className={styles.info}>
                                <div className={styles.name}>{name}</div>
                                <div>
                                    {userStore.followers}关注
                                    <Divider type='vertical'/>
                                    {/*{points}荣誉值*/}
                                    {userStore.totalDig}点赞
                                </div>
                            </div>
                        </div>
                        <div className={styles.introduce}>
                            <Typography.Text>
                                简介：{introduce||'暂无简介。'}
                            </Typography.Text>
                        </div>
                        <div className={styles.btnGroup}>
                            {
                                getFollowButton()
                            }

                        </div>
                    </div>
                )
            }
        </div>
    )
})
