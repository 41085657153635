import styles from "./App.module.less";
import { Card, Layout } from "@arco-design/web-react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Header, Footer, Auth, UserForm } from "./components";
import WechatBind from "./pages/Setting/WechatBind";
import Certify from "./pages/Setting/Certify";
import Privacy from "./pages/Setting/Privacy";
import Delete from "./pages/Setting/Delete";
import { getQuery } from "./utils";
import {
  Home,
  About,
  Post,
  Category,
  Posts,
  User,
  Register,
  Rules,
  Login,
  BindWechat,
  Creation,
  Message,
  Setting,
  Chat,
  Account,
    Search,
  AppDownload
} from "./pages";
import MessageList from "./pages/Message/MessageList";
import "@arco-design/web-react/es/style/index.less";
import { install } from "resize-observer";
if (!window.ResizeObserver) install();
import VConsole from "vconsole";

if (getQuery().debug) {
  const vConsole = new VConsole();
}

function App() {
  return (
    <BrowserRouter>
      <div className={""}>
        <Layout className={styles.layout}>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            {/*<Route path="download" element={<Download />} />*/}
            <Route path="/app/download" element={<AppDownload />} />
            <Route path="post" element={<Post />} />
            <Route path="posts" element={<Posts />} />
            <Route path="category" element={<Category />} />
            <Route path="user/:userId" element={<User />} />
            <Route path="user" element={<User />} />
            <Route path="register" element={<Register />} />
            <Route path="login" element={<Login />} />
            <Route path="bind" element={<BindWechat />} />
            <Route path="rules" element={<Rules />} />
            <Route path="chat" element={<Chat />} />
            <Route path="/creation" element={<Auth />}>
              <Route path="/creation" element={<Creation />} />
            </Route>
            <Route path="/message" element={<Auth />}>
              <Route path="/message" element={<Message />}></Route>
            </Route>
            <Route path="search" element={<Search />} />
            <Route path="/setting" element={<Auth />}>
              <Route path="/setting" element={<Setting />}>
                <Route
                  path="account"
                  element={
                    <Account/>
                  }
                ></Route>
                <Route path="bind" element={<WechatBind />} />
                <Route path="certify" element={<Certify />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="delete" element={<Delete />} />
              </Route>
            </Route>
          </Routes>
          <Routes>
            <Route path="about" element={<Footer />} />
            <Route path="download" element={<Footer />} />
            <Route path="register" element={<Footer />} />
            <Route path="login" element={<Footer />} />
            <Route path="bind" element={<Footer />} />
            <Route path="rules" element={<Footer />} />
            <Route path="/creation" element={<Footer />} />
          </Routes>
        </Layout>
      </div>
    </BrowserRouter>
  );
}

export default App;
