import styles from "./index.module.less";
import { Content } from "../../components";
import logo from "../../assets/images/logoApp.jpeg";
import apk from "../../assets/app.apk";
import { Input, Space ,Button,Drawer,Switch,Popconfirm,Message,Badge} from '@arco-design/web-react';

export default function () {
  return (
      <iframe src="https://imeikan.net/download.html" height={'100%'} frameBorder="0"></iframe>
  );
}
