import {Message, Menu, Grid, Input, Button, Divider, Avatar, Form, Card} from '@arco-design/web-react';
import styles from "./index.module.less"
import {Author, SelfAvatar,CommentList,Article,Content,QrCard} from "../../components";
import {userSelf,post} from "../../store";
import { observer } from "mobx-react-lite"
import {useEffect, useState} from "react"
import {getQuery} from '../../utils'
import * as request from '../../utils/request'
import {Comments} from "../../store/comments";
import {QRCodeCanvas} from 'qrcode.react';
import {Helmet} from "react-helmet";

const Row = Grid.Row;
const Col = Grid.Col;

export default observer(function (){
    const [postData,setPostData]=useState({})
    const [commentStore]=useState(new Comments())
    const [comment,setComment]=useState()
    const [isSubmitting,setIsSubmitting]=useState(false)
    const query=getQuery()
    const postId=query.id
    const commentsQuery={post:postData._id}
    useEffect(()=>{
        post.getPost({_id:postId})
            .then(result=>{
                setPostData(result)
                // post.viewPost(postId)
            })
    },[])
    const {user:{wechat}={}}=postData
    const handleCommentChange=(value)=>{
        setComment(value)
    }
    const handleSubmitComment=async ()=>{
        if(!comment) return Message.info('请先填写评论内容！')
        if(isSubmitting) return
        setIsSubmitting(true)
        try {
            const result=await request.post('/api/comment/create',{
                user:userSelf.id,
                content:comment,
                post:postData._id,
            })
            if(result){
                await commentStore.loadmore(commentsQuery,true)
                setComment('')
                Message.success('发表成功！')
            }
        }finally {
            setIsSubmitting(false)
        }
    }
    return (
        <Content className={styles.postPage}>
            <Helmet>
                <meta property="og:title" content={postData.title} />
                <meta property="og:description" content={postData.content} />
                <meta property="og:type" content="article" />
                <meta
                    property="og:image"
                    content="https://source.unsplash.com/random/1000x500"
                />
            </Helmet>
            <Row gutter={24}>
                <Col span={18}>
                    <div className={styles.post}>
                        <Article key={postData._id} item={postData} />
                        <div className={styles.commentWrap}>
                            <div className={styles.inputWrap}>
                                <SelfAvatar style={{marginRight:'20px'}} size={30}/>
                                <Input.TextArea
                                    value={comment}
                                    maxLength={300}
                                    showWordLimit
                                    onChange={handleCommentChange}
                                    placeholder='发表评论.' />
                            </div>
                            <div className={'arco-form-extra'} style={{paddingLeft:50}}>内容发布者观点及评论仅供其表达个人看法或观点，并不表明美刊立场。</div>

                            <div className={styles.btnWrap}>
                                <Button disabled={isSubmitting} onClick={handleSubmitComment} type={'primary'}>发表评论</Button>
                            </div>
                        </div>
                        {
                            postData._id&&(
                                <CommentList
                                    key={'comments'+postData._id}
                                    store={commentStore}
                                    query={commentsQuery}  />
                            )
                        }
                    </div>
                </Col>
                <Col span={6}>
                    <Card title='关于作者' bordered={false} className={styles.about_writer} >
                        <Author size={'middle'} user={postData.user} followed={postData.followed}/>
                    </Card>

                    <Card title='扫码分享给朋友' bordered={false} className={styles.share}>
                        <div className={styles.qr}>
                            <QRCodeCanvas value={window.location.href}/>
                        </div>
                    </Card>

                </Col>
            </Row>
        </Content>
    )
})
